






import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutAdmin: () => import("@/layouts/presets/admin.vue"),
  },
})
export default class PageAdmin extends Vue {}
